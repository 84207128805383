import * as React from "react"
import { Link, graphql } from "gatsby"

import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

class BlogPostTemplate extends React.Component<BlogPostTemplateProps, {}> {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article>
          <header
            style={{
              marginBottom: rhythm(9 / 5),
            }}
          >
            <h1
              style={{
                marginTop: rhythm(0.6),
                marginBottom: rhythm(1 / 2),
                fontWeight: 200,
                fontSize: "3rem",
              }}
            >
              {post.frontmatter.title}
            </h1>
            <time
              style={{
                display: `block`,
              }}
            >
              {post.frontmatter.date}
            </time>
          </header>
          <MDXRenderer>{post.body}</MDXRenderer>
          <footer>
            <br />
          </footer>
        </article>

        <nav>
          <ul
            className={"list-none ml-0"}
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Layout>
    )
  }
}

interface Frontmatter {
  title: string
  date: string
  description: string
}

interface MdxItem {
  id: string
  excerpt: string
  body: string
  frontmatter: Frontmatter
}

interface PageSiteMetadata {
  title: string
}

interface PageSite {
  siteMetadata: PageSiteMetadata
}

interface BlogPostData {
  mdx: MdxItem
  site: PageSite
}

interface BlogPostTemplateProps {
  data: BlogPostData
  location: Location
  pageContext: any
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
